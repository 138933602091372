import {
  Box,
  Flex,
  Heading,
  Icon,
  IconButton,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { graphql, Link, navigate } from "gatsby";
import React, { useMemo } from "react";
import Layout from "../components/Layout";
import { useModuleProgress, useModules } from "../data/modulesAPI";

import { BsCheckCircle, BsPlayCircle } from "react-icons/bs";

import {
  FiArrowLeft,
  FiCloud,
  FiFileText,
  FiLink,
  FiVideo,
} from "react-icons/fi";
import ModuleProgressIndicator from "../components/ModuleProgressIndicator";
import SearchComponent from "../components/SearchComponent";

type Props = {
  data: {
    mdx: {
      body: string;
      frontmatter: {
        title: string;
        index: number;
        time: number;
        moduleNumber: number;
        primaryColour: string;
        secondaryColour: string;
        featuredImage: {
          childImageSharp: {
            gatsbyImageData: any;
          };
        };
      };
    };
  };
};

export default function Template({
  data: { mdx }, // this prop will be injected by the GraphQL query below.
}: Props) {
  const modules = useModules();
  const [isMobile] = useMediaQuery("(max-width: 540px)");
  const grey = useColorModeValue("white", "gray.900");

  const module = useMemo(() => {
    const module = modules.find(
      (module) => module.index === mdx.frontmatter.moduleNumber
    );
    if (!module) {
      navigate("/");
    }
    return module!;
  }, []);

  // const { data: moduleProgress, isLoading: moduleProgressLoading } =
  //   useModuleProgress(module.index.toString());
  const { data: moduleProgress, loading: moduleProgressLoading } =
    useModuleProgress(module.index.toString());

  const bg = useColorModeValue("white", "gray.800");
  const blue = useColorModeValue("blue.400", "blue.300");
  const blue2 = useColorModeValue("blue.700", "blue.500");

  return (
    <Layout>
      <Box>
        <Stack direction="row" mb={4}>
          <IconButton
            aria-label="go back"
            as={Link}
            to="/73281"
            icon={<Icon as={FiArrowLeft} />}
            bgColor={bg}
            isRound
            size="lg"
            shadow="md"
          />
          <Heading pl={3} pt={3} size="md">
            {mdx.frontmatter.title}
          </Heading>
          <Spacer />
          {isMobile ? <SearchComponent asInput={false} /> : null}
        </Stack>
        <Flex my={6} justify="space-between" alignItems="center">
          <Flex rounded={"xl"} px={3} py={2} bgColor={grey} alignItems="center">
            <Text mr={2} fontWeight="bold" fontSize={{ base: "sm", md: "md"}}>
              Module
            </Text>
            <ModuleProgressIndicator
              moduleProgress={moduleProgress}
              isLoading={Boolean(moduleProgressLoading)}
            />
          </Flex>
          <Flex bgColor={grey} px={3} py={2} rounded={"xl"}>
            {module.topics
              .map((t) => t.time)
              .reduce((prev, curr) => prev + curr)}{" "}
            mins to finish
          </Flex>
        </Flex>

        <Stack spacing={3} bg={bg} rounded={"xl"} textAlign="left" p={8}>
          <VStack
            divider={<StackDivider borderColor={blue} />}
            spacing={4}
            align="stretch"
          >
            {module.topics.map((topic, i) => {
              const locked =
                (topic?.keywords?.includes("reflection") &&
                  (moduleProgress?.completed ?? 0) < i) ||
                (topic?.keywords?.includes("end") && (moduleProgress?.completed !== moduleProgress?.total)); //Last topic is unlocked if Module 9 is complete

              return (
                <>
                  <Stack
                    opacity={locked ? 0.5 : 1}
                    direction="row"
                    align="center"
                    as={Link}
                    to={locked ? "" : "/" + topic.slug}
                    key={"Overview page" + i}
                  >
                    <Box>
                      <Heading size="md" mb={1} color={blue2}>
                        {topic.title}
                      </Heading>
                      <Text size="xs" opacity={0.8} mb={1}>
                        {topic.time} minutes to finish
                      </Text>
                      <Stack direction="row" mb={1}>
                        {topic.keywords && topic.keywords.includes("text") ? (
                          <FiFileText />
                        ) : null}
                        {topic.keywords && topic.keywords.includes("video") ? (
                          <FiVideo />
                        ) : null}
                        {topic.keywords &&
                        topic.keywords.includes("reflection") ? (
                          <FiCloud />
                        ) : null}
                        {topic.keywords &&
                        topic.keywords.includes("resources") ? (
                          <FiLink />
                        ) : null}
                      </Stack>
                    </Box>
                    <Spacer />
                    <Box alignContent="right" fontSize="4xl" color={blue}>
                      {(moduleProgress?.completed ?? 0) > i ? (
                        <BsCheckCircle />
                      ) : (
                        <BsPlayCircle />
                      )}
                    </Box>
                  </Stack>
                </>
              );
            })}
          </VStack>
        </Stack>
      </Box>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        index
        moduleNumber
        primaryColour
        secondaryColour
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              height: 300
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
